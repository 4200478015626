.displayRating {
  display: flex;

  .currentNoteRedStars {
    transform: scale(1.2);
    width: 26px;
    color: $color1;
  }

  .emptyNoteGreyStars {
    transform: scale(1.2);
    width: 26px;
    color: $color3;
  }
}

@media only screen and (max-width: 425px) {
  .displayRating {
    display: flex;

    .currentNoteRedStars {
      transform: scale(1);
      width: 24px;
    }

    .emptyNoteGreyStars {
      transform: scale(1);
      width: 24px;
    }
  }
}
