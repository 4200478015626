.containerHome {
  max-width: 1240px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .containerHome {
    margin: 0 10px;
  }
  
}